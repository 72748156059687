import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { http } from 'viem';
import { arbitrumSepolia } from 'wagmi/chains'

import { env } from '@/config';

/**
 * @see [rainbowkit](https://www.rainbowkit.com/docs/installation) for where `projectId` come from.
 */
export const wagmiConfig = getDefaultConfig({
  transports: {
    [arbitrumSepolia.id]: http(env.RPC_URL),
  },
  appName: env.RAINBOWKIT_APP_NAME || '',
  projectId: env.WALLET_CONNECT_PROJECT_ID || '',
  chains: [arbitrumSepolia],
  ssr: true,
})
