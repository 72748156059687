/**
 * Retrieve env variable in an isomorphic app. Some code
 * would run on both server and client code that need
 * env variable to initialize it. They can use the variables
 * by this script straight up.
 */
import { z } from 'zod'

export const schema = z.object({
  NODE_ENV: z.enum(['production', 'development', 'test'] as const),
  DATABASE_PATH: z.string(),
  DATABASE_URL: z.string(),
  SESSION_SECRET: z.string(),
  INTERNAL_COMMAND_TOKEN: z.string(),
  HONEYPOT_SECRET: z.string(),
  CACHE_DATABASE_PATH: z.string(),
  // If you plan on using Sentry, uncomment this line
  SENTRY_DSN: z.string(),
  // If you plan to use Resend, uncomment this line
  // RESEND_API_KEY: z.string(),
  ALLOW_INDEXING: z.enum(['true', 'false']).optional(),

  WALLET_CONNECT_PROJECT_ID: z.string(),
  RAINBOWKIT_APP_NAME: z.string(),

  RPC_URL: z.string(),

  GAME_CONTRACT_ADDRESS: z.string(),
})

export function init() {
  const parsed = schema.safeParse(process.env)

  if (parsed.success === false) {
    console.error(
      '❌ Invalid environment variables:',
      parsed.error.flatten().fieldErrors,
    )

    throw new Error('Invalid environment variables')
  }
}


export type ENV = z.infer<typeof schema>;
export type PartialEnv = Partial<ENV>;

declare global {
  var ENV: PartialEnv
  namespace NodeJS {
    interface ProcessEnv extends ENV { }
  }
  interface Window {
    ENV: PartialEnv
  }
}


export const getIsomorphicENV = (): PartialEnv => {
  return typeof window === 'undefined'
    ? process.env
    : window.ENV;
}

/**
 * Specify environment variable that you want to expose to the
 * application here.
 */
export const env: PartialEnv = {
  NODE_ENV: getIsomorphicENV().NODE_ENV,
  ALLOW_INDEXING: getIsomorphicENV().ALLOW_INDEXING,
  WALLET_CONNECT_PROJECT_ID: getIsomorphicENV().WALLET_CONNECT_PROJECT_ID,
  RAINBOWKIT_APP_NAME: getIsomorphicENV().RAINBOWKIT_APP_NAME,
  RPC_URL: getIsomorphicENV().RPC_URL,
  GAME_CONTRACT_ADDRESS: getIsomorphicENV().GAME_CONTRACT_ADDRESS,
  SENTRY_DSN: getIsomorphicENV().SENTRY_DSN || '',
};
